import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const PosicionamientoSEMPage = () => {
    const data = useStaticQuery(graphql`
    query {
      posicionamientoSemImage1: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-sem/por-que-una-empresa-debe-anunciarse-internet-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      posicionamientoSemImage2: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-sem/somos-tus-consultores-sem-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      posicionamientoSemImage3: file(relativePath: { eq: "images/sections/marketing-digital/posicionamiento-sem/que-buscamos-con-campaigns-sem-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Posicionamiento SEM"
                description="Agencia de Marketing Digital en Barcelona experta en publicidad online. Posicionamos tu web en lo más alto de Google mediante Google ads, posicionamiento SEM."
            />

            <BannerPage
                title="Agencia Posicionamiento SEM"
                text="Expertos en la gestión y optimización de campañas de Google Ads"
                color="green"
            />

            <SectionTextImage
                title1="¿Por qué anunciarse "
                title2="en Internet?"
                text={<Fragment>
                    La <strong>publicidad online</strong> va de la mano de cualquier empresa y es la llave de muchos de sus éxitos. Es por eso, que invertir en medios digitales es una forma de garantizar conversiones y con ello, el éxito en el mercado.<br /><br />
                    <strong>Internet es el canal de comunicación por excelencia</strong> para destinar tu presupuesto publicitario ya que puedes escoger dónde y en qué invertir. ¿Empezamos?
                </Fragment>}
                image={data.posicionamientoSemImage1.childImageSharp.fluid}
                imageAlt="Por qué anunciarse en Internet - Agencia Posicionamiento SEM"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="Somos tus "
                title2="consultores SEM"
                text={<Fragment>
                    En IBX somos <strong>especialistas en publicidad online</strong>. Administramos campañas Paid en Google Ads (publicidad en Google) y Social Media Ads (publicidad en redes sociales). Solo con un objetivo, incrementar tus ventas mediante la conversión directa. <br /><br />
                    ¿Quieres incrementar tus visitas y ventas? ¿Quieres recibir más formularios, llamadas o compras en tu comercio electrónico?
                </Fragment>}
                image={data.posicionamientoSemImage2.childImageSharp.fluid}
                imageAlt="Conultores SEM - Agencia Posicionamiento SEM"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Qué buscamos con "
                title2="las campañas SEM?"
                text={<Fragment>
                    <ul class="text-left">
                        <li><strong>Orientación</strong>: Buscamos el retorno de inversión (ROI).</li>
                        <li><strong>Diversificación</strong>: Diferentes modalidades de anuncios y campañas para poder captar a diferentes clientes y necesidades.</li>
                        <li><strong>Segmentación</strong>: Campañas destinadas a un público objetivo para buscar siempre la conversión.</li>
                        <li><strong>Optimización</strong>: Presupuestos ajustados según las necesidades de cada cliente.</li>
                    </ul>
                </Fragment>}
                image={data.posicionamientoSemImage3.childImageSharp.fluid}
                imageAlt="Qué buscamos con las campañas SEM - Agencia Posicionamiento SEM"
                color="green"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="green"
                silverText="Ideal para webs de servicios con una geolocalización concreta"
                silverPrice="190"
                silverPriceCurrency="€/mes"
                silverFeatures={["Defininción de palabras clave", "Generación y gestión de campañas de Google Ads (Search)", "Planificación y optimización de campañas", "Generación de informes de rendimiento y consecución de objetivos", "Estudio de nuevas estrategias para captar nuevos clientes"]}
                silverException={[]}
                goldText="Ideal tiendas online con ámbito nacional"
                goldPrice="250"
                goldPriceCurrency="€/mes"
                goldFeatures={["Defininción de palabras clave", "Generación y gestión de campañas de Google Ads (Search)", "Planificación y optimización de campañas", "Generación de informes de rendimiento y consecución de objetivos", "Estudio de nuevas estrategias para captar nuevos clientes"]}
                goldException={[]}
                diamondText="Ideal para webs o tiendas online en expansión"
                diamondPrice="390"
                diamondPriceCurrency="€/mes"
                diamondFeatures={["Defininción de palabras clave", "Generación y gestión de campañas de Google Ads (Search, Display & Shopping)", "Planificación y optimización de campañas", "Generación de informes de rendimiento y consecución de objetivos", "Estudio de nuevas estrategias para captar nuevos clientes"]}
                diamondException={[]}
            />

            <CallToAction
                color="green"
                title="¿Quieres aumentar tus conversiones de forma rápida?"
                text={<Fragment>
                    ¡Somos la <strong>agencia SEM de Barcelona</strong> que te hará vender más!<br />
                    ¡Consigue más LEADS!
                    </Fragment>}
                button="¿Hacemos que te encuentren?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default PosicionamientoSEMPage
